<template>
    <v-list dense height="500px" style="overflow-y: scroll">
        <template v-for="({ type, devices, icon }, index) in audioDevices">
            <v-radio-group
                v-model="device[type]"
                :key="index"
                class="ma-0 pa-0"
                @change="deviceSelect(type, $event)"
                hide-details
            >
                <v-subheader
                    class="font-weight-bold text-uppercase text-body-2 pa-0 ma-0"
                    >Audio {{ type }}</v-subheader
                >
                <template v-for="({ deviceId, label }, j) in devices">
                    <v-list-item :key="j">
                        <v-list-item-action>
                            <v-radio :value="deviceId"> </v-radio>
                        </v-list-item-action>
                        <v-list-item-title>
                            {{ label }}
                        </v-list-item-title>
                        <v-list-item-icon>
                            <v-icon color="primary" class="mt-2">{{
                                icon
                            }}</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-divider
                        :key="`divider-${j}`"
                        v-if="j < devices.length - 1"
                    ></v-divider>
                </template>
            </v-radio-group>
        </template>
    </v-list>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "phone-settings",
    components: {},
    props: {},
    data() {
        return {
            audioDevices: [],
            device: {
                Input: "",
                Output: "",
            },
        };
    },
    computed: {
        ...mapGetters("Calls", [
            "audioInputDeviceId",
            "audioOutputDeviceId",
            "devices",
        ]),
    },
    watch: {
        audioInputDeviceId: {
            immediate: true,
            handler(val) {
                this.device.Input = val;
            },
        },
        audioOutputDeviceId: {
            immediate: true,
            handler(val) {
                this.device.Output = val;
            },
        },
        devices: {
            immediate: true,
            async handler(val) {
                console.log("Audio Devices %o", val);
                if (val) {
                    this.audioDevices = [
                        {
                            type: "Input",
                            devices: val.filter(
                                (device) => device.kind === "audioinput"
                            ),
                            icon: "mdi-microphone",
                        },
                        {
                            type: "Output",
                            devices: val.filter(
                                (device) => device.kind === "audiooutput"
                            ),
                            icon: "mdi-volume-low",
                        },
                    ];
                }
            },
        },
    },
    methods: {
        ...mapActions("Calls", ["setOutputAudio", "setInputAudio"]),
        async deviceSelect(type, deviceId) {
            switch (type) {
                case "Input":
                    this.setInputAudio(deviceId);
                    break;
                case "Output":
                    this.setOutputAudio(deviceId);
                    break;
            }
        },
    },
    async mounted() {},
};
</script>

<style scoped>
</style>