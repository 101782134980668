var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    {
      staticStyle: { "overflow-y": "scroll" },
      attrs: { dense: "", height: "500px" }
    },
    [
      _vm._l(_vm.audioDevices, function(ref, index) {
        var type = ref.type
        var devices = ref.devices
        var icon = ref.icon
        return [
          _c(
            "v-radio-group",
            {
              key: index,
              staticClass: "ma-0 pa-0",
              attrs: { "hide-details": "" },
              on: {
                change: function($event) {
                  return _vm.deviceSelect(type, $event)
                }
              },
              model: {
                value: _vm.device[type],
                callback: function($$v) {
                  _vm.$set(_vm.device, type, $$v)
                },
                expression: "device[type]"
              }
            },
            [
              _c(
                "v-subheader",
                {
                  staticClass:
                    "font-weight-bold text-uppercase text-body-2 pa-0 ma-0"
                },
                [_vm._v("Audio " + _vm._s(type))]
              ),
              _vm._l(devices, function(ref, j) {
                var deviceId = ref.deviceId
                var label = ref.label
                return [
                  _c(
                    "v-list-item",
                    { key: j },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-radio", { attrs: { value: deviceId } })],
                        1
                      ),
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(label) + " ")
                      ]),
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mt-2",
                              attrs: { color: "primary" }
                            },
                            [_vm._v(_vm._s(icon))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  j < devices.length - 1
                    ? _c("v-divider", { key: "divider-" + j })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }